import { IconButton } from '@xo-union/tk-component-icons';
import React, { type FC } from 'react';
import RfqModal from '../../containers/InlineRFQ/components/RfqModal/RfqModal';
import { useInlineRfqForm } from '../../containers/InlineRFQ/hooks/useInlineRfqForm/useInlineRfqForm';
import { generateDefaultField } from '../../containers/InlineRFQ/utils';
import Styles from './styles.scss';

export const StartConversation: FC = () => {
	const localFormContext = useInlineRfqForm({
		freeTextLabel: '',
		textAreaInitialValueOverride: generateDefaultField('textarea'),
		initiator: 'NavBar CTA',
	});
	return (
		<>
			<RfqModal context={localFormContext} />
			<div className={Styles.conversationContainer}>
				<IconButton
					size="md"
					className={Styles.icon}
					aria-label="Start a chat"
					name="chat"
					onClick={localFormContext.openModal}
				/>
			</div>
		</>
	);
};
